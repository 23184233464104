exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-[zone]-index-tsx": () => import("./../../../src/pages/[zone]/index.tsx" /* webpackChunkName: "component---src-pages-[zone]-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-privacy-tsx": () => import("./../../../src/pages/legal/privacy.tsx" /* webpackChunkName: "component---src-pages-legal-privacy-tsx" */),
  "component---src-pages-legal-terms-tsx": () => import("./../../../src/pages/legal/terms.tsx" /* webpackChunkName: "component---src-pages-legal-terms-tsx" */),
  "component---src-pages-tools-colors-tsx": () => import("./../../../src/pages/tools/colors.tsx" /* webpackChunkName: "component---src-pages-tools-colors-tsx" */),
  "component---src-pages-tools-tarjeta-tsx": () => import("./../../../src/pages/tools/tarjeta.tsx" /* webpackChunkName: "component---src-pages-tools-tarjeta-tsx" */),
  "component---src-pages-tools-whatsapp-tsx": () => import("./../../../src/pages/tools/whatsapp.tsx" /* webpackChunkName: "component---src-pages-tools-whatsapp-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

